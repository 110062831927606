exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-applets-index-js": () => import("./../../../src/pages/applets/index.js" /* webpackChunkName: "component---src-pages-applets-index-js" */),
  "component---src-pages-art-index-js": () => import("./../../../src/pages/art/index.js" /* webpackChunkName: "component---src-pages-art-index-js" */),
  "component---src-pages-coding-index-js": () => import("./../../../src/pages/coding/index.js" /* webpackChunkName: "component---src-pages-coding-index-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-research-js": () => import("./../../../src/pages/research.js" /* webpackChunkName: "component---src-pages-research-js" */),
  "component---src-pages-teaching-js": () => import("./../../../src/pages/teaching.js" /* webpackChunkName: "component---src-pages-teaching-js" */),
  "component---src-templates-applet-js-content-file-path-applets-domain-coloring-index-mdx": () => import("./../../../src/templates/applet.js?__contentFilePath=/opt/build/repo/applets/domain-coloring/index.mdx" /* webpackChunkName: "component---src-templates-applet-js-content-file-path-applets-domain-coloring-index-mdx" */),
  "component---src-templates-applet-js-content-file-path-applets-julia-sets-index-mdx": () => import("./../../../src/templates/applet.js?__contentFilePath=/opt/build/repo/applets/julia-sets/index.mdx" /* webpackChunkName: "component---src-templates-applet-js-content-file-path-applets-julia-sets-index-mdx" */),
  "component---src-templates-art-project-js-content-file-path-art-projects-blaschke-products-index-mdx": () => import("./../../../src/templates/art-project.js?__contentFilePath=/opt/build/repo/art_projects/blaschke-products/index.mdx" /* webpackChunkName: "component---src-templates-art-project-js-content-file-path-art-projects-blaschke-products-index-mdx" */),
  "component---src-templates-art-project-js-content-file-path-art-projects-cellular-automata-index-mdx": () => import("./../../../src/templates/art-project.js?__contentFilePath=/opt/build/repo/art_projects/cellular-automata/index.mdx" /* webpackChunkName: "component---src-templates-art-project-js-content-file-path-art-projects-cellular-automata-index-mdx" */),
  "component---src-templates-art-project-js-content-file-path-art-projects-julia-sets-index-mdx": () => import("./../../../src/templates/art-project.js?__contentFilePath=/opt/build/repo/art_projects/julia-sets/index.mdx" /* webpackChunkName: "component---src-templates-art-project-js-content-file-path-art-projects-julia-sets-index-mdx" */),
  "component---src-templates-art-project-js-content-file-path-art-projects-newton-fractal-index-mdx": () => import("./../../../src/templates/art-project.js?__contentFilePath=/opt/build/repo/art_projects/newton-fractal/index.mdx" /* webpackChunkName: "component---src-templates-art-project-js-content-file-path-art-projects-newton-fractal-index-mdx" */),
  "component---src-templates-art-project-js-content-file-path-art-projects-space-filling-curves-index-mdx": () => import("./../../../src/templates/art-project.js?__contentFilePath=/opt/build/repo/art_projects/space-filling-curves/index.mdx" /* webpackChunkName: "component---src-templates-art-project-js-content-file-path-art-projects-space-filling-curves-index-mdx" */),
  "component---src-templates-coding-project-js-content-file-path-coding-projects-elementizer-index-mdx": () => import("./../../../src/templates/coding-project.js?__contentFilePath=/opt/build/repo/coding_projects/elementizer/index.mdx" /* webpackChunkName: "component---src-templates-coding-project-js-content-file-path-coding-projects-elementizer-index-mdx" */),
  "component---src-templates-coding-project-js-content-file-path-coding-projects-herbie-index-mdx": () => import("./../../../src/templates/coding-project.js?__contentFilePath=/opt/build/repo/coding_projects/herbie/index.mdx" /* webpackChunkName: "component---src-templates-coding-project-js-content-file-path-coding-projects-herbie-index-mdx" */),
  "component---src-templates-coding-project-js-content-file-path-coding-projects-lean-index-mdx": () => import("./../../../src/templates/coding-project.js?__contentFilePath=/opt/build/repo/coding_projects/lean/index.mdx" /* webpackChunkName: "component---src-templates-coding-project-js-content-file-path-coding-projects-lean-index-mdx" */)
}

